import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import VideoCommentsView from './view';

import { decryptText } from '@library/enc-dec';
import { handleCommentReply } from '@helpers/ReplyInputbox';

import * as CommentsService from '@services/Comments';

import { produce } from 'immer';

import {
	updateCommentLikeCount,
	updateCommentDislikeCount,
	updateCommentStatusChange,
	updateReplyLikeCount,
	updateReplyDislikeCount,
	updateReplyStatusChange,
	updateCommentReply,
} from '@helpers/comments';

function VideoComments(props) {
	const [userDetails, setUserDetails] = useState(null);
	const [apiCommentData, setApiCommentData] = useState({});

	const { id } = useParams();

	//START : UseState for published, heldForReview, rejected
	const [selectedFilter, setSelectedFilter] = useState('');
	const [commentsData, setCommentData] = useState({});
	const [sortErrorMessage, setSortErrorMessage] = useState('');

	//END : END for published, heldForReview, rejected

	//START : Usestate for sort
	const sortContainerRef = useRef(null);
	const [sortContainerVisiable, setSortContainerVisiable] = useState(false); // In init the container is not visiable
	//END : Usestate for sort

	const [noCommentsFound, setNoCommentsFound] = useState(false);

	useEffect(() => {
		if (id !== undefined) {
			const videoDocId = id;
			getUserDetails();
			getComments(videoDocId);
		} else {
			setNoCommentsFound(true);
		}

		document.addEventListener('mousedown', handleOutsideClick);

		return () => {
			document.removeEventListener('mousedown', handleOutsideClick);
		};
	}, []);

	const getUserDetails = async () => {
		try {
			// Getting admin details
			let authToken = decryptText(localStorage.getItem('aEmediat'));
			const response = await CommentsService.getUserDetails(authToken);
			if (response.success) {
				setUserDetails(response.data);
			}
		} catch (err) {
			console.log('Error coming from handling getUserDetails()', err);
		}
	};

	const getComments = async (videoDocId) => {
		// Get the data of a given video id
		try {
			// Getting all the comments
			let authToken = decryptText(localStorage.getItem('aEmediat'));
			const response = await CommentsService.getAllVideoComments(
				videoDocId,
				authToken
			);

			if (response.success) {
				setApiCommentData(response);
				setCommentData(response);
				setSelectedFilter('none');
				return;
			}
			setNoCommentsFound(true);
		} catch (err) {
			console.log('Error coming while getting comments', err);
		}
	};

	//Fn to handle hiding of popup's when clicked outside the respective container
	const handleOutsideClick = (event) => {
		if (
			sortContainerRef.current &&
			!sortContainerRef.current.contains(event.target)
		) {
			setSortContainerVisiable(false);
		}
	};

	// START : fn for filter : published, heldForReview, rejected
	const filterComments = (filterOption, comments) => {
		let lCommentData = {};
		if (comments?.data?.length > 0) {
			if (filterOption !== 'none') {
				let filteredComments = produce(comments, (draft) => {
					draft.data = draft.data.filter(
						(comment) => comment.status === filterOption
					);
				});

				if (filteredComments.data.length > 0) {
					lCommentData = filteredComments;
				} else {
					lCommentData = apiCommentData;
					setSortErrorMessage(
						'No comments for the selected filter hence, show complete data'
					);
				}
			} else {
				lCommentData = apiCommentData;
			}
		} else {
			lCommentData = apiCommentData;
			setSortErrorMessage(
				'No comments for the selected filter hence, show complete data'
			);
		}

		setCommentData(lCommentData);
		closeAllOpenUIContainers();
	};

	const closeAllOpenUIContainers = () => {
		let userCommentContainer_ele = document.getElementsByClassName(
			'userCommentContainer'
		);
		for (let i = 0; i < userCommentContainer_ele.length; i++) {
			userCommentContainer_ele[i].style.display = 'none';
		}
		let accordions = document.getElementsByClassName('accordion');
		for (let i = 0; i < accordions.length; i++) {
			let collapseElement = accordions[i].querySelector('.collapse');
			if (collapseElement.classList.contains('show')) {
				collapseElement.classList.remove('show');
			}
		}
	};

	const handleFilter = (event, filter) => {
		setSortErrorMessage('');
		if (filter === 'published') {
			let lCommentData = {};
			if (selectedFilter !== 'published') {
				lCommentData = apiCommentData;
				filterComments('published', lCommentData);
				setSelectedFilter('published');
			} else {
				filterComments('none', apiCommentData);
				setSelectedFilter('none');
			}
		}

		// Handling heldForReview comments
		if (filter === 'heldForReview') {
			setSortErrorMessage('');
			let lCommentData = {};
			if (selectedFilter !== 'heldForReview') {
				lCommentData = apiCommentData;
				filterComments('heldForReview', lCommentData);
				setSelectedFilter('heldForReview');
			} else {
				filterComments('none', apiCommentData);
				setSelectedFilter('none');
			}
		}

		// Handling rejected comments
		if (filter === 'rejected') {
			setSortErrorMessage('');
			let lCommentData = {};
			if (selectedFilter !== 'rejected') {
				lCommentData = apiCommentData;
				filterComments('rejected', lCommentData);
				setSelectedFilter('rejected');
			} else {
				filterComments('none', apiCommentData);
				setSelectedFilter('none');
			}
		}
	};

	// END : fn for filter : published, heldForReview, rejected

	// START : fn for sorting
	const sort = (event) => {
		setSortContainerVisiable(true);
	};

	const handleSortingData = (event, sortType) => {
		if (commentsData.data.length > 0) {
			if (sortType === 'newestFirst') {
				let temp = [...commentsData.data].sort(
					(a, b) => new Date(b.createdAt) - new Date(a.createdAt)
				);

				let sortedComments = {};
				sortedComments.success = apiCommentData.success;
				sortedComments.data = temp;
				setCommentData(sortedComments);
				setSortContainerVisiable(false);
			}

			if (sortType === 'top') {
				// This is sort bt likes
				let temp = [...commentsData.data].sort(
					(a, b) => b.likesCount - a.likesCount
				);

				let sortedComments = {};
				sortedComments.success = apiCommentData.success;
				sortedComments.data = temp;
				setCommentData(sortedComments);
				setSortContainerVisiable(false);
			}
		}
	};
	// END : fn for sorting

	const handleLikeComment = async (userComment) => {
		try {
			let payload = {
				commentId: userComment._id,
				videoDocId: userComment.videoDocId,
			};
			let authToken = decryptText(localStorage.getItem('aEmediat'));
			const response = await CommentsService.likeComment(payload, authToken);

			if (response.success) {
				// Status tells that increase likes count
				let status = response.data ? true : false;
				let updatedComments = updateCommentLikeCount(
					commentsData,
					userComment._id,
					status
				);

				setCommentData(updatedComments);

				// Updating already fetched api data
				let updatedApiData = updateCommentLikeCount(
					apiCommentData,
					userComment._id,
					status
				);

				setApiCommentData(updatedApiData);
			}
		} catch (err) {
			console.log('Error coming while handling comment', err);
		}
	};

	const handleDislikeComment = async (userComment) => {
		try {
			let payload = {
				commentId: userComment._id,
				videoDocId: userComment.videoDocId,
			};
			let authToken = decryptText(localStorage.getItem('aEmediat'));
			const response = await CommentsService.dislikeComment(payload, authToken);

			if (response.success) {
				// Status tells that increase likes count
				let status = response.data ? true : false;
				let updatedComments = updateCommentDislikeCount(
					commentsData,
					userComment._id,
					status
				);

				setCommentData(updatedComments);

				// Updating already fetched api data
				let updatedApiData = updateCommentDislikeCount(
					apiCommentData,
					userComment._id,
					status
				);

				setApiCommentData(updatedApiData);
			}
		} catch (err) {
			console.log('Error coming while handling comment', err);
		}
	};

	const handleCommentStatus = async (status, commentId) => {
		try {
			let payload = {
				commentId: commentId,
				status: status,
			};
			let authToken = decryptText(localStorage.getItem('aEmediat'));

			const response = await CommentsService.updateCommentStatus(
				payload,
				authToken
			);

			if (response.success) {
				const { _id, status } = response.data;
				// Updating current filtered set
				let updatedComments = updateCommentStatusChange(commentsData, _id, status);
				setCommentData(updatedComments);

				// Updating already fetched api data
				let updatedApiData = updateCommentStatusChange(apiCommentData, _id, status);

				setApiCommentData(updatedApiData);
			}
		} catch (err) {
			console.log('Error coming while handling comments status update', err);
		}
	};

	const handleReplyLike = async (userReply, videoDocId) => {
		try {
			let payload = {
				replyId: userReply._id,
				commentId: userReply.commentId,
				videoDocId: videoDocId,
			};
			let authToken = decryptText(localStorage.getItem('aEmediat'));
			const response = await CommentsService.likeReply(payload, authToken);
			if (response.success) {
				// Status tells that increase likes count
				let status = response.data ? true : false;
				let updatedComments = updateReplyLikeCount(
					commentsData,
					userReply.commentId,
					userReply._id,
					status
				);

				setCommentData(updatedComments);

				// Updating already fetched api data
				let updatedApiData = updateReplyLikeCount(
					apiCommentData,
					userReply.commentId,
					userReply._id,
					status
				);

				setApiCommentData(updatedApiData);
			}
		} catch (err) {
			console.log('Error coming while handling reply like', err);
		}
	};

	const handleReplyDislike = async (userReply, videoDocId) => {
		try {
			let payload = {
				replyId: userReply._id,
				commentId: userReply.commentId,
				videoDocId: videoDocId,
			};
			let authToken = decryptText(localStorage.getItem('aEmediat'));
			const response = await CommentsService.dislikeReply(payload, authToken);
			if (response.success) {
				// Status tells that increase dislikes count
				let status = response.data ? true : false;
				let updatedComments = updateReplyDislikeCount(
					commentsData,
					userReply.commentId,
					userReply._id,
					status
				);

				setCommentData(updatedComments);

				// Updating already fetched api data
				let updatedApiData = updateReplyDislikeCount(
					apiCommentData,
					userReply.commentId,
					userReply._id,
					status
				);

				setApiCommentData(updatedApiData);
			}
		} catch (err) {
			console.log('Error coming while handling reply dislike', err);
		}
	};

	const handleReplyStatus = async (status, replyId) => {
		try {
			let authToken = decryptText(localStorage.getItem('aEmediat'));
			let payload = {
				replyId: replyId,
				status: status,
			};
			const response = await CommentsService.updateReplyStatus(payload, authToken);

			if (response.success) {
				const { _id, commentId, status } = response.data;

				// Updating current filtered set
				let updatedComments = updateReplyStatusChange(
					commentsData,
					commentId,
					_id,
					status
				);

				setCommentData(updatedComments);

				// Updating already fetched api data
				let updatedApiData = updateReplyStatusChange(
					apiCommentData,
					commentId,
					_id,
					status
				);

				setApiCommentData(updatedApiData);
			}
		} catch (err) {
			console.log('Error coming while handling reply status', err);
		}
	};

	const handleAddingCommentReply = async (reply, docId) => {
		try {
			let authToken = decryptText(localStorage.getItem('aEmediat'));
			let payload = {
				commentId: docId,
				reply: reply,
			};
			const response = await CommentsService.addReply(payload, authToken);
			if (response.success) {
				const updatedComments = updateCommentReply(
					commentsData,
					docId,
					response.data
				);

				setCommentData(updatedComments);

				let updatedApiData = updateCommentReply(
					apiCommentData,
					docId,
					response.data
				);

				setApiCommentData(updatedApiData);
			}
		} catch (err) {
			console.log('Error coming while adding reply', err);
		}
	};

	const handleAddingReplyOfReply = async (reply, docId, commentId) => {
		try {
			let authToken = decryptText(localStorage.getItem('aEmediat'));
			let payload = {
				replyOnId: docId,
				commentId: commentId,
				reply: reply,
			};
			const response = await CommentsService.addReplyOfReply(payload, authToken);

			if (response.success) {
				const updatedComments = updateCommentReply(
					commentsData,
					commentId,
					response.data
				);

				setCommentData(updatedComments);

				let updatedApiData = updateCommentReply(
					apiCommentData,
					commentId,
					response.data
				);

				setApiCommentData(updatedApiData);
			}
		} catch (err) {
			console.log('Error coming while adding reply', err);
		}
	};

	const handleAddReply = (reply, docId, replyType, commentId) => {
		if (replyType === 'replyOnComment') {
			handleAddingCommentReply(reply, docId);
		} else {
			handleAddingReplyOfReply(reply, docId, commentId);
		}
	};

	return (
		<>
			<VideoCommentsView
				commentsData={commentsData}
				//START : UseState * fn for published, heldForReview, rejected
				selectedFilter={selectedFilter}
				commentAction={handleCommentStatus}
				handleFilter={handleFilter}
				//END : UseState * fn for published, heldForReview, rejected

				noCommentsFound={noCommentsFound}
				//START : UseState & fn for sorting
				sort={sort}
				sortContainerRef={sortContainerRef}
				sortContainerVisiable={sortContainerVisiable}
				handleSortingData={handleSortingData}
				//END : UseState & fn for sorting

				handleLikeComment={handleLikeComment}
				handleDislikeComment={handleDislikeComment}
				// Handling Replies
				handleReplyLike={handleReplyLike}
				handleReplyDislike={handleReplyDislike}
				handleReplyStatus={handleReplyStatus}
				handleCommentReply={(event, commentId, replyType) =>
					handleCommentReply(
						event,
						commentId,
						replyType,
						userDetails,
						handleAddReply
					)
				}
			/>
		</>
	);
}

export default VideoComments;
